import { Box, Grid, Typography } from '@material-ui/core'
import * as React from 'react'
import Root from '../layout/Root'

// TODO This does not work in mobile.
// markup
const IndexPage = () => (
	<Root>
		<main>
			<Box paddingY='5em'>
				<Grid container direction='row'>
					<Grid item xs={6}>
						<Box paddingRight={2}>
							<Typography
								variant='h1'
								align='right'
								style={{ fontWeight: 'bold' }}
							>
								Thomas Hunter
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={6}>
						<Box borderLeft={2} paddingLeft={2}>
							<Typography variant='h1'>Software Engineer</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</main>
	</Root>
)

export default IndexPage
